//
// Base
//

// Body
body {
  background: $page-bg;
}

// Desktop Mode
@include media-breakpoint-up(lg) {
  // Wrapper
  .wrapper {
    // Fixed Header
    .header-fixed & {
      padding-top: get($header-config, desktop, fixed, height);
    }

    // Fixed Content Head
    .header-fixed.subheader-fixed.subheader-enabled & {
      padding-top: get($header-config, desktop, fixed, height) +
        get($subheader-config, fixed, height);
    }

    // Fixed Aside
    .aside-fixed & {
      padding-left: get($aside-config, base, width);
    }

    // Minimize Aside
    .aside-fixed.aside-minimize:not(.aside-minimize-hover) & {
      padding-left: get($aside-config, base, minimized-width);
    }
  }

  // Container
  .container,
  .container-fluid {
    padding: 0 get($page-padding, desktop);
  }
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
  // Wrapper
  .wrapper {
    // Fixed Header
    .header-mobile-fixed & {
      padding-top: get($header-config, tablet-and-mobile, fixed, height);
    }

    // Fixed Subheader
    .header-mobile-fixed.subheader-mobile-fixed.subheader-enabled & {
      padding-top: get($header-config, tablet-and-mobile, fixed, height) +
        get($subheader-config, fixed, height-mobile);
    }
  }

  // Container
  .container,
  .container-fluid {
    max-width: none;
    padding: 0 get($page-padding, tablet-and-mobile);
  }
}
.chatgried {
  display: grid;
  grid-template-columns: max(210px) 1fr;
  gap: 0;
}

.chat-modal-body-height {
  height: calc(100vh - 216px);
}

.new-chat-grid {
  display: grid;
  grid-template-columns: 350px 1fr;
  gap: 0 20px;
  justify-content: space-between;
  .new-chat-grid-items {
    height: calc(100vh - 236px);
    overflow-x: hidden;
    overflow: auto;
    .chat-view-show {
      height: calc(100vh - 310px);
      overflow: auto;
      overflow-x: hidden;
      .main-message-box.ta-right {
        float: right;
      }
      .main-message-box {
        float: left;
        position: relative;
        margin-bottom: 5px;
        clear: both;
        width: 100%;
      }

      .message-dt.st3 {
        float: left;
      }

      .message-inner-dt > div {
        /* float: right; */
        width: 60%;
        /* background-color: #9ab1d717; */
        font-size: 14px;
        line-height: 21px;
        padding: 0px 15px 0px 15px;
        color: #3a3a3a;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px;
        font-weight: 400 !important;
      }

      .message-dt.st3 .message-inner-dt > div {
        float: left;
        width: fit-content;
        min-width: 40%;
      }

      .message-dt.st3 .message-inner-dt > div p.message-chat {
        background-color: #003264e0;
        color: #fff;
        width: 100%;
        padding: 10px 10px;
        float: left;
        font-weight: 400 !important;
        border-radius: 1px 20px 20px 20px;
        word-break: break-word;
      }

      .main-message-box {
        float: left;
        position: relative;
        margin-bottom: 5px;
        clear: both;
        width: 100%;
      }
      .main-message-box.ta-right .message-dt {
        padding-left: 0;
        float: right;
        padding-right: 5px;
        width: 60%;
        padding-left: 5px;
        text-align: left;
      }
      .message-inner-dt {
        float: left;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px;
        width: 100%;
      }
      .main-message-box.ta-right .message-inner-dt > div p.message-chat {
        background-color: #003264;
        color: #fff;
        width: 100%;
        padding: 10px 10px 10px;
        float: left;
        font-weight: 400 !important;
        border-radius: 20px 1px 20px 20px;
      }
      .main-message-box.ta-right .message-inner-dt > div {
        float: right;
        width: fit-content;
        min-width: 40%;
      }
      .message-inner-dt > div {
        /* float: right; */
        width: 60%;
        /* background-color: #9ab1d717; */
        font-size: 14px;
        line-height: 21px;
        padding: 0px 15px 0px 15px;
        color: #3a3a3a;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px;
        font-weight: 400 !important;
      }
    }
    .chat-message-send-grid {
      display: grid;
      grid-template-columns: 1fr 120px;
      gap: 0 20px;
      padding: 20px 0 0 0;
      .chat-message-send-grid-items {
        input {
          width: 100%;
          height: 45px;
          border: 1px solid #d0d0d0;
          font-size: 14px;
          line-height: 18px;
          color: #232323;
          border-radius: 6px;
          &:focus {
            outline: none;
          }
        }
        button {
          background-color: #003264;
          color: #fff;
          padding: 5px 12px;
          font-size: 16px;
          width: 100%;
          height: 45px;
          border-radius: 4px;
          border: none;
        }
      }
    }
    .chat-profile-name-style {
      padding: 1rem 0;
      border-bottom: 1px solid #d0d0d0;
      p {
        font-size: 16px;
        color: #757575;
        margin: 0;
      }
    }
  }
}
